import React, { useState, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Flashcards.module.css";

const Card = ({ data, playText, flipText }) => {
  const [isFlipped, setFlipped] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const audioRef = useRef();

  const { word, translation, wordSimple, wordPro } = data;

  const handlePlay = () => {
    if (!isPlaying) {
      setPlaying(true);
      audioRef.current?.play();
    } else if (audioRef.current?.ended) {
      audioRef.current?.play();
    }
  };

  const toggleFlip = () => {
    setFlipped((prevVal) => !prevVal);
    handlePlay();
  };

  return (
    <div className={`${styles.container} ${isFlipped ? styles.containerFlipped : ""}`}>
      <div className={styles.containerInner}>
        <div className={styles.containerFront}>
          <div className={styles.cardFrontContainer}>
            <div className={styles.cardFrontTextContainer}>
              <div>
                <span style={{ fontWeight: "bold" }}>{word}</span>
              </div>
              {wordSimple && (
                <div>
                  <span>{wordSimple}</span>
                </div>
              )}
              {wordPro && (
                <div>
                  <span>{wordPro}</span>
                </div>
              )}
            </div>
            {!isFlipped && (
              <div className={styles.flipIconContainer} onClick={toggleFlip}>
                <StaticImage src="../../../images/turn.svg" alt={flipText} width={20} height={20} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.containerBack}>
          <div className={styles.cardBackontainer}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              {data.imageURL && (
                <div className={styles.itemImage}>
                  <img
                    src={data.imageURL}
                    title={`${word}-${translation}`}
                    alt={word}
                    height={60}
                    width={60}
                  />
                </div>
              )}
              <div>
                <div className={styles.cardBackWordTextContainer}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>{word}</span>
                  </div>
                  {wordSimple && (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {wordSimple}
                      </span>
                    </div>
                  )}
                  {wordPro && (
                    <div>
                      <span
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {wordPro}
                      </span>
                    </div>
                  )}
                </div>
                <div className={styles.cardBackTranslationTextContainer}>
                  <span>{translation}</span>
                </div>
              </div>
            </div>
            <div>
              {data.audioURL && (
                <div
                  onClick={() => {
                    handlePlay();
                  }}
                >
                  <button
                    name={`${playText} ${word}`}
                    className={styles.playButton}
                    aria-label={playText}
                  >
                    <StaticImage
                      src="../../../images/play-button-arrowhead.svg"
                      alt={playText}
                      height={20}
                      width={20}
                    />
                  </button>
                  <audio ref={audioRef} src={data.audioURL} preload="none"></audio>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Flashcards = ({ items, playText, rightArrowText, leftArrowText, flipText }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentCardData = items[currentIndex];

  const isFirst = currentIndex === 0;
  const isLast = currentIndex === items.length - 1;

  return (
    <div className={styles.mainContainer}>
      <div>
        <div className={styles.cardAndArrowsContainer}>
          <div
            className={styles.arrowContainer}
            style={{
              visibility: isFirst ? "hidden" : "visible",
            }}
            onClick={() => setCurrentIndex((prevVal) => Math.max(0, prevVal - 1))}
          >
            <StaticImage
              className={styles.iconArrow}
              width={20}
              height={20}
              src="../../../images/left-arrow-in-circular-button.svg"
              alt={leftArrowText}
            />
          </div>
          <Card
            data={currentCardData}
            key={currentCardData.word}
            playText={playText}
            flipText={flipText}
          />
          <div
            className={styles.arrowContainer}
            style={{
              visibility: isLast ? "hidden" : "visible",
            }}
            onClick={() => setCurrentIndex((prevVal) => Math.min(items.length - 1, prevVal + 1))}
          >
            <StaticImage
              className={styles.iconArrow}
              width={20}
              height={20}
              src="../../../images/right-arrow-in-circular-button.svg"
              alt={rightArrowText}
            />
          </div>
        </div>
      </div>
      <div className={styles.currentIndexProgressContainer}>
        <span>
          {currentIndex + 1} / {items.length}
        </span>
      </div>
    </div>
  );
};

export default Flashcards;
