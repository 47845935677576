import React, { useRef } from "react";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import Layout from "../../components/Layout";

import * as Constants from "../../lib/constants";

import {
  getGooglePlayURLFromAppID,
  getAppStoreURLFromAppID,
  getWordLangKey,
} from "../../lib/helpers";

import * as styles from "./Course.module.css";
import Breadcrumb from "../../components/Breadcrumb";
import Flashcards from "./Flashcards";
import GoogleAds from "../../components/GoogleAds";
import CourseItems from "../../components/CourseItems";

const CourseTemplate = ({
  pageContext: { course, recommendedCourses, withYoutube, hreflangArr, pagePath },
  path,
}) => {
  const { langUser, langToLearn } = course;

  const appData = Constants.AVAILABLE_APPS.find((item) => item.key === getWordLangKey(langToLearn));

  const coursesByLangIndexPagePath = `/${course.listsBreadcrumbSlug}`;

  return (
    <Layout
      title={course.metaTitle}
      metaDesc={course.metaDesc}
      iosID={appData.iosID}
      hreflangArr={hreflangArr}
      langUser={langUser}
      ogUrlPath={pagePath}
      canonicalPath={pagePath}
    >
      <Breadcrumb
        items={[
          { label: course.rootBreadcrumbLabel, to: `/courses?l=${langUser}` },
          {
            label: course.listsBreadcrumbLabel,
            to: coursesByLangIndexPagePath,
          },
          { label: course.name },
        ]}
      />
      <main>
        <header
          style={{
            display: "flex",
            paddingBottom: 20,
            borderBottom: "1px solid #dbdbdb",
          }}
        >
          <img alt={course.name} height={100} width={100} src={course.imageURL} />
          <div className={styles.courseNameCountContainer}>
            <h1 className={styles.courseName}>{course.pageTitle}</h1>
            <span className={styles.courseItemCount}>{course.wordCountText}</span>
          </div>
        </header>
        <Flashcards
          items={course.items}
          playText={course.playText}
          rightArrowText={course.rightArrowText}
          leftArrowText={course.leftArrowText}
          flipText={course.flipText}
        />
        {withYoutube && (
          <div className={styles.iframeYoutubeContainer}>
            <LiteYouTubeEmbed id={course.youtubeVideoID} title={`${course.name} YouTube video`} />
          </div>
        )}
        <div style={{ padding: "0 15px", margin: "5px 0", boxSizing: "border-box" }}>
          <GoogleAds slot="9202174501" />
        </div>

        <CourseItems
          items={course.items}
          shouldDisplayAd={(itemIndex) => itemIndex % 5 === 4}
          playText={course.playText}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 20,
            paddingTop: 20,
          }}
        >
          <h2 style={{ textAlign: "center" }}>{course.recommendationsTitle}</h2>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            className={styles.recommendedCoursesContainer}
          >
            {recommendedCourses.map((item) => {
              const imageSize = 50;
              return (
                <div className={styles.recommendedCourseItem} key={item.fullPath}>
                  <Link to={item.fullPath}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <LazyLoad
                        style={{
                          width: imageSize,
                          height: imageSize,
                          marginRight: 15,
                        }}
                        height={imageSize}
                        once
                      >
                        {item.imageURL && (
                          <img
                            src={item.imageURL}
                            width={imageSize}
                            height={imageSize}
                            alt={item.name}
                          />
                        )}
                      </LazyLoad>
                      <span>{item.name}</span>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className={styles.linkVocabularyListsIndex}>
            <Link to={coursesByLangIndexPagePath}>
              <span>{course.recommendationsButton}</span>
            </Link>
          </div>
        </div>
        <div style={{ textAlign: "center", paddingBottom: 50 }}>
          <h2>{course.downloadTitle}</h2>
          <p>{course.downloadDescription}</p>
          <div className={styles.footerStoreButtonsContainer}>
            <a href={getAppStoreURLFromAppID(appData.iosID)}>
              <img src="/download-app-store.svg" alt={course.downloadIos} height={45} width={135} />
            </a>
            <a href={getGooglePlayURLFromAppID(appData.androidID)}>
              <img
                src="/download-google-play.svg"
                alt={course.downloadAndroid}
                height={45}
                width={152}
              />
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default CourseTemplate;
