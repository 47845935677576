// extracted by mini-css-extract-plugin
export var arrowContainer = "Flashcards-module--arrowContainer--09605";
export var cardAndArrowsContainer = "Flashcards-module--cardAndArrowsContainer--c202c";
export var cardBackTranslationTextContainer = "Flashcards-module--cardBackTranslationTextContainer--903d7";
export var cardBackWordTextContainer = "Flashcards-module--cardBackWordTextContainer--a7e3d";
export var cardBackontainer = "Flashcards-module--cardBackontainer--bde95";
export var cardFrontContainer = "Flashcards-module--cardFrontContainer--83917";
export var cardFrontTextContainer = "Flashcards-module--cardFrontTextContainer--3d90d";
export var container = "Flashcards-module--container--5a6a6";
export var containerBack = "Flashcards-module--containerBack--8cb57";
export var containerFlipped = "Flashcards-module--containerFlipped--1bdc4";
export var containerFront = "Flashcards-module--containerFront--f9ff9";
export var containerInner = "Flashcards-module--containerInner--0625a";
export var currentIndexProgressContainer = "Flashcards-module--currentIndexProgressContainer--fdefb";
export var flipIconContainer = "Flashcards-module--flipIconContainer--962e6";
export var iconArrow = "Flashcards-module--iconArrow--af6d5";
export var itemImage = "Flashcards-module--itemImage--3e4b4";
export var mainContainer = "Flashcards-module--mainContainer--7a1b7";
export var playButton = "Flashcards-module--playButton--bd08c";